import Types from '../../classes/types';

const server = 'globre-mit.co';

const wdApiServer = 'https://globre-mit.co/api/';
const wssServer = [`wss://globre-mit.co/wss1/`, `wss://globre-mit.co/wss2/`];
const reportServer = `https://globre-mit.co/report/`;
const transactionServer =  'https://globre-mit.co/ts/';

export const AppConfig = {
    name: "Globremit",
    domain: "globre-mit.co",
    mailSupport: "support@globremit.com",
    isEnableDynamicDomain: true,
    showDefaultTickerTradePanel: "EUR/USD",
    showTickerChartsForNewUser: [
        "EUR/USD", 
        "BTC/USD", 
        "XAU/USD",
        "Brent Crude Oil",
        "S&P 500",
    ],

    googleMapApiToken:'AIzaSyAaCvCGg8rrlub9pI0grjOQPpvjkdWPiMg',
    autoLogOutTimeOut: 0,

    wlMainPage: {
        name: 'Globremit',
        url: `https://globremit.com`,
    },

    // show languages
    languages : [
        Types.LANGUAGE_RU,
        Types.LANGUAGE_EN,
        Types.LANGUAGE_TR,
        // Types.LANGUAGE_PL,
        // Types.LANGUAGE_FR,
        // Types.LANGUAGE_DE,
    ],

    labelsImg: {
        logo: require('./assets/labels/logo.svg'),
        logoDark: require('./assets/labels/logo.svg'),
        logoMobile: require('./assets/labels/logo.svg'),
        logoAuth: require('./assets/labels/logo.svg'),
        iconOrderSellSrc: null,
        iconOrderBuySrc: null,
    },
    authBackgroundImage: require('./assets/labels/bg.jpg'),

    history: {
        balanceHistory: {
            fields: {
                method: {
                    name: 'Method',
                    show: false,
                },
                
                transactionHash: {
                    name: 'Transaction Hash',
                    show: false,
                },
            }
        }
    },

    features: {
        showTradeOnDemo: false,
        isEnableRegistration: true,
        isEnabledNewAuth: true,
        isEnabledAuth2: true,
        isEnableLandingAuth: true,
        isEnableOrderBookRandomizer: true,
        
        isEnabledEmailVerification: true,
        isEnabledNotAuthEmailVerification: true,

        isEnableUserForbiddenPage: true,
        isEnableRealNotExistMessage: true,

        showAccountTypeInfoModal: true,
        allowChangePasswordModal: true,
        isEnableUserProfile: true,
        isEnableDeposit: true,
        isEnableWithdraw: true,
        isEnableInvestingTab: false,
        isEnableLiveSupportTab: false,
        isEnableMobileFastTrade: false,
        showButtonsOfHeader: false,

        isEnableTierVerification: true,
        isEnableCheckTierOnDeposit: false,
        isKYCVerifiedPriority: true,
        isEnableNotificationsTab: true,
        showNonCreatedWallets: false,
        isOrderBookExpanded: true,
    },

    settingsGeneral: {
        //changeLanguage: false,
    },

    registration: {
        RESEND_EMAIL_TIME : 20, // seconds
        notAvailableCountries: [],
        useServerParamsForCreateAccounts: true,
        TermsConditionsUrl: 'https://globre-mit.co/docs/terms_and_conditions.html',
        // PrivacyPolicyUrl: 'https://globre-mit.co/docs/privacy_policy.html',
        // PrivacyPolicyUrlRu: 'https://globre-mit.co/docs/privacy_policy_ru.html',
        PrivacyPolicyUrl: 'https://globre-mit.co/docs/user_agreement.html',
        PrivacyPolicyUrlRu: 'https://globre-mit.co/docs/user_agreement_ru.html',
        CookiePolicyUrl: 'https://globre-mit.co/docs/cookie_policy.html',
        // TermsOfUseUrl: 'https://globre-mit.co/docs/terms_of_use.html',
        // TermsOfUseUrlRu: 'https://globre-mit.co/docs/terms_of_use_ru.html',
        TermsOfUseUrl: 'https://globre-mit.co/docs/refund_cancellation_policy.html',
        TermsOfUseUrlRu: 'https://globre-mit.co/docs/refund_cancellation_policy_ru.html',
        RefundPolicyUrl: 'https://globre-mit.co/docs/refund_cancellation_policy.html',
        RefundPolicyUrlRu: 'https://globre-mit.co/docs/refund_cancellation_policy_ru.html',
        UserAgreementUrl: 'https://globre-mit.co/docs/user_agreement.html',
        UserAgreementUrlRu: 'https://globre-mit.co/docs/user_agreement_ru.html',
        CreateAutoLoginUrl:  true,
        forcePhoneVerification: false,
        showAffiliateCode: false,
        showPromoCode: true,
        
    },

    onBoarding: {
        isShowOnBoardingDialog: false,
    },

    personalData: {
        showEmailVerifyStatus: false, 
        showPhoneVerifyStatus: false,
        fields: {
            firstName: {
                name: 'FirstName',
                available: true,
                require: false,
            },
            lastName: {
                name: 'LastName',
                available: true,
                require: false,
            },
            company: {
                name: 'Company',
                available: true,
                require: false,
            },
            country: {
                name: 'Country',
                available: true,
                require: false,
            },
            state: {
                name: 'State',
                available: true,
                require: false,
            },
            tax: {
                name: 'Tax',
                available: false,
                require: false,
            },
            city: {
                name: 'City',
                available: true,
                require: false,
            },
            address: {
                name: 'Address1',
                available: true,
                require: false,
            },
            zipCode: {
                name: 'ZIPCode',
                available: true,
                require: false,
            },
            dateOfBirth: {
                name: 'dateOfBirth',
                available: true,
                require: false,
            },
        },
    },

    defines: {
        "SERVERS_URL": wssServer,
        "WD_API": {
            "SERVER": wdApiServer,
            "KEY": '0000'
        },
        "REPORT_URL": reportServer,
        "TRANSACTION_URL": transactionServer,
        "CONFIRM_URL": `https://${server}/#/confirm`,
        "SIGNUP_URL": `/signup`,
        "SING_UP_URL": `https://${server}/cabinet/signup`,
        "WITHDRAWAL_URL": `https://${server}/cabinet/clientarea/withdraw`,
        //"PROFILE_URL": `https://${server}/cabinet/clientarea/profile`,
        "PROFILE_URL": `mailto:support@globremit.com`,
        "FAQ_URL": `https://${server}/cabinet/info/faq`,
        "DEPOSIT_URL": `https://${server}/cabinet/clientarea/deposit/`,
        "CABINET_REMINDER_URL": `https://${server}/cabinet/reminder`,
        "AUTH_URL": "/auth",
        "MAIN_URL": "/",
        "MAIN_MOBILE_URL": `https://${server}/mobile/`,
        "CABINET_URL": "",
        "EXTERNAL_AUTH_URL": `https://${server}/cabinet/login`,
        "MINING_URL": `https://${server}/mining/`,
        "MINING_MOB_URL": `https://${server}/mining/mobile/`,
        "EXCHANGE_URL": `https://${server}/exchange/`,
        "INVESTING_URL": "/investing",
        "EXCHANGE_MOB_URL": `https://${server}/exchange/mobile/`,
        "DEFAULT_LANGUAGE": 'en',
        "CALL_BACK_URL": '/callBackSender',
        "EDUCATION_URL": `https://${server}/education/`,
    },

    createAccount: {
        singleRealByCurrency: true,
        autoCreateLeverage: '1:20',
        currencyList: {
            [Types.ACCOUNT_DEALER_NAME_REAL]: ['USD', 'EUR', 'BTC'],
            [Types.ACCOUNT_DEALER_NAME_DEMO]: ['USD'],
            default: ['USD'],
        },
    },

    headerButtons : {
        welcomeText: 'name'
    },

    site: {
        isShowButtonChangeTheme: false,
        theme: "light", // dark / light
    },


    deposit: {
        minTotalDeposit: 0,
        skipFirstStep: true, // skip first step of Deposit if available only one payment method
        availablePaymentMethods: [
            Types.PAYMENT_TYPE_USDT_TRC20,
            Types.PAYMENT_TYPE_BITCOIN
        ],
        currencyPaymentMethods: {
            default: [
                Types.PAYMENT_TYPE_USDT_TRC20,
                Types.PAYMENT_TYPE_BITCOIN
            ],
            RUB: []
        },
        currencyValidation: {
            [Types.PAYMENT_TYPE_MIDEXPAY_WIDGET]: {
                default: {
                    minValue: 2000.00,
                    maxValue: 1000000.00,
                    minLength: 4,
                    maxLength: 9
                }
            },
        },
    },

    depositViaCrypto: {
        howToBuyLink: {
            [Types.PAYMENT_TYPE_USDT]: '',
            [Types.PAYMENT_TYPE_BITCOIN]: '',
            [Types.PAYMENT_TYPE_ETHEREUM]: '',
        },
        faqLink: `https://${server}/cabinet/info/faq`,
        partners: [
            {
                name: 'binance.com',
                logo: 'binance.svg',
                url: 'https://binance.com',
            },
            {
                name: 'htx.com',
                logo: 'htx.svg',
                url: 'https://www.htx.com/ru-ru/?invite_code=ve248%C2%A0%22Huobi%22',
            },
            {
                name: 'Bybit',
                logo: 'bybit.svg',
                url: 'https://www.bybit.com/ru-RU',
            },
            {
                name: 'Kraken',
                logo: 'kraken.svg',
                url: 'https://www.kraken.com/',
            },
            {
                name: 'coinbase.com',
                logo: 'coinbase.svg',
                url: 'https://www.coinbase.com',
            },
            {
                name: 'garantex.org',
                logo: 'garantex.svg',
                url: 'https://garantex.org',
            },
        ],
    },

    withdraw: {
        paymentMethodCustomName: {
            [Types.PAYMENT_TYPE_VISA_MK_MIR]: 'Visa/Mastercard'
        },
        allowWithdrawBonus: true,
        availablePaymentMethods: [
            Types.PAYMENT_TYPE_USDT_TRC20,
            Types.PAYMENT_TYPE_BITCOIN,
            //Types.PAYMENT_TYPE_ETHEREUM,
            Types.PAYMENT_TYPE_VISA_MK_MIR,
            Types.PAYMENT_TYPE_WIRE_TRANSFER,
        ],
        currencyPaymentMethods: {
            default: [
                Types.PAYMENT_TYPE_BITCOIN,
                Types.PAYMENT_TYPE_VISA_MK_MIR,
            ],
            USD: [
                Types.PAYMENT_TYPE_BITCOIN,
                Types.PAYMENT_TYPE_USDT_TRC20,
                Types.PAYMENT_TYPE_VISA_MK_MIR,
            ],
            RUB: [
                Types.PAYMENT_TYPE_VISA_MK_MIR,
                Types.PAYMENT_TYPE_WIRE_TRANSFER
            ]
        },
        predefinedAmounts: {
            default: []
        },
        currencyConfig: {
            'default': {
                validation: {
                    minValue: 18.00,
                    maxValue: 1000000.00,
                    minLength: 2,
                    maxLength: 9
                }
            },
            'USD': {
                validation: {
                    minValue: 18.00,
                    maxValue: 1000000.00,
                    minLength: 2,
                    maxLength: 9
                }
            },
            'EUR': {
                validation: {
                    minValue: 18.00,
                    maxValue: 1000000.00,
                    minLength: 2,
                    maxLength: 9
                }
            },
            'BTC': {
                validation: {
                    minValue: 0.01,
                    maxValue: 30.00,
                    minLength: 1,
                    maxLength: 12
                }
            },
        },
    },

    instrumentDetails: {
        leverageDisplayFormat: 2, // 1 - 1:5, 2 - 5x
        useValuesFromConfig: false,
        staticFields: {
            maxTradeNumbers: {
                name: 'Max number of trades',
                value: {
                    default: 200,
                },
            },
            contractLength: {
                name: 'Contract Length',
                value: {
                    default: 'Perpetual',
                    1795: '3 Month',
                },
            },
            positionLimit: {
                name: 'Position Limit',
                value: {
                    default: '$50 000',
                    1795: '$100 000',
                }
            },
        },
    },

    orderEdit: {
        hideSLTPPoints: true,
        defaultTP: 100,
        defaultSL: 50,
        defaultTPType: Types.SLTP_UNIT_PRICE,
        defaultSLType: Types.SLTP_UNIT_PRICE,
    },

    orderFormMode: Types.ORDERFORM_VALIDATE_SL_TP,

    DefaultInstrumetLeverage: 0.2,

    spreadType: 'SPREAD_IN_USD', // one of 'SPREAD_IN_LOTS' or 'SPREAD_IN_USD'

    stockChart: {
        isEnableLowLiquidityMessage: true,
        defaultSelectedIndicators: [
            Types.INDICATOR_BB,
            Types.INDICATOR_STO
        ],
        chartSettings: {
            chartType: 'CANDLES',
            showPriceIndicator: true,
            showLevelsProfitLoss: true,
            showPendingOrders: true,
            showVolume: false,
            frame: '15F',
        },
        noGapBars: true,
    },

    simplePeer: {
        iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' },
            { urls: 'stun:stun2.l.google.com:19302' },
            { urls: 'stun:stun3.l.google.com:19302' },
            { urls: 'stun:stun4.l.google.com:19302' },
            {
                urls: `turn:${server}:5349`,
                username: 'guest',
                credential: 'some$$password',
            }
        ]
    },

    mtMessage: {
        enable: true,
        begin: "21:55", // mt begin time UTC+0
        duration: 10, // minutes
    },

    menu: {
        wlNewsPage: true,
        calendar: true,
        education: true,
        wlMainPage: true,
        twoFactorAuth: true,
        account: {
            items: {
                createAccount: false,
                transactionHistory: true,
                createWallet: false,
            }
        },

        reports: {
            enable: true,
            items: {
                depositWithdrawals: true,
                marginHistory: true
            }
        },

        service: {
            enable: false,
        },

        security: {
            items: {
                twoFactorAuth: true,
            }
        },

        support: {
            enable: true,
            items: {
                faq: false,
                liveSupport: false
            }
        },
        liveSupport: {
            enable: false,
        },
        referral: false,
    },
};

export default AppConfig;